import React, { Component } from "react"
import { loadableP5 as P5Wrapper } from "./Loadable"
// import Sketch from "./sketch"
// import SketchRedShine from "./SketchRedShine"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default class App extends Component {
  render() {
    return (
      <>
        <StaticImage
          src="../assets/images/polygonLayerArt.png"
          alt="contact"
          className="polygonLayer"
          placeholder="tracedSVG"
          layout="fullWidth"
        ></StaticImage>
      </>
    )
  }
}
